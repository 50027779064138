import { template as template_3fb3abf131ea45f9a1fbfc5688123251 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_3fb3abf131ea45f9a1fbfc5688123251(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
