import { template as template_55f78db0a2b1475a9fc20c618d03e453 } from "@ember/template-compiler";
const SidebarSectionMessage = template_55f78db0a2b1475a9fc20c618d03e453(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
