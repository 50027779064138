import { template as template_3d9fe71bd7b44ec48a8add523c5d30d2 } from "@ember/template-compiler";
const WelcomeHeader = template_3d9fe71bd7b44ec48a8add523c5d30d2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
